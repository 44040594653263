<template>
  <div class="pucontainer">
    <div class="back">
      <span class="iconfont icon-jtback" @click="$router.go(-1)">{{
        $t('common.back')
      }}</span>
    </div>
    <q-form @submit="onSubmit">
      <div class="infoCon">
        <h5>{{ $t('account.set_email') }}</h5>
        <div class="inputdiv">
          <q-input
            maxlength="11"
            type="tel"
            v-model="form.phone"
            autofocus
            :label="$t('login_mod.phone_t')"
            :rules="[
              (val) =>
                (val !== null && val !== '') || $t('login_mod.enter_phone'),
              (val) => phoneRule(val) || $t('login_mod.phone_format')
            ]"
          />
        </div>
        <div class="inputdiv">
          <q-input
            type="text"
            maxlength="6"
            v-model="form.code"
            :label="$t('account.verification_code')"
            :rules="[
              (val) =>
                (val !== null && val !== '') ||
                $t('login_mod.send_verification_code')
            ]"
          >
            <template v-slot:append>
              <q-btn
                :loading="SendCodeLoading"
                :disable="SendCodeDisabled"
                color="primary"
                :label="$t('login_mod.send_verification_code') + time"
                @click="sendCode()"
              /> </template
          ></q-input>
          <!-- <p>有效时间为60秒</p> -->
        </div>
        <div class="inputdiv">
          <q-input
            type="email"
            maxlength="30"
            v-model="form.email"
            :label="$t('account.email')"
            :rules="[
              (val) =>
                (val !== null && val !== '') || $t('login_mod.enter_email'),
              (val) => emailRule(val) || $t('login_mod.email_format')
            ]"
          />
        </div>
        <div class="inputdiv">
          <q-input
            type="text"
            maxlength="6"
            v-model="form.code2"
            :label="$t('login_mod.verification_code')"
            :rules="[
              (val) =>
                (val !== null && val !== '') ||
                $t('login_mod.send_verification_code')
            ]"
          >
            <template v-slot:append>
              <q-btn
                :loading="SendCodeLoading2"
                :disable="SendCodeDisabled2"
                color="primary"
                :label="$t('login_mod.send_verification_code') + time2"
                @click="sendCode2()"
              /> </template
          ></q-input>
        </div>
        <div class="infoBtn">
          <q-btn
            type="submit"
            class="full-width"
            color="primary"
            :label="$t('common.submit')"
          />
        </div>
      </div>
    </q-form>
  </div>
</template>

<script>
import { pwdRule, emailRule, phoneRule } from '@/common/formRules'
import { emailBindApi } from '@/api/login'
export default {
  data () {
    return {
      form: {
        phone: '',
        email: '',
        code: '',
        code2: ''
      },
      time: '',
      SendCodeLoading: false,
      SendCodeDisabled: false,
      time2: '',
      SendCodeLoading2: false,
      SendCodeDisabled2: false
    }
  },
  methods: {
    async onSubmit () {
      const res = await emailBindApi({
        mark: this.form.phone,
        code: this.form.code,
        newmark: this.form.email,
        newcode: this.form.code2
      })
      if (res.status === 3) {
        this.$router.go(-1)
      } else {
        this.$q.notify(this.$i18n.locale === 'en' ? res.contenten : res.content)
      }
    },
    // 发送验证码
    async sendCode () {
      if (this.SendCodeLoading || this.SendCodeDisabled) {
        return
      }
      if (this.form.phone === '' || !phoneRule(this.form.phone)) {
        this.$q.notify(this.$t('login_mod.phone_format'))
        return
      }
      this.SendCodeLoading = true
      const res = await emailBindApi({
        mark: this.form.phone
      })
      if (res.status === 1) {
        this.timerStart()
      } else {
        this.$q.notify(this.$i18n.locale === 'en' ? res.contenten : res.content)
      }
      this.SendCodeLoading = false
    },
    // 发送验证码2
    async sendCode2 () {
      if (this.SendCodeLoading2 || this.SendCodeDisabled2) {
        return
      }
      if (this.form.code === '') {
        this.$q.notify(this.$t('login_mod.enter_verification_code'))
        return
      }
      if (this.form.email === '' || !emailRule(this.form.email)) {
        this.$q.notify(this.$t('login_mod.email_format'))
        return
      }
      this.SendCodeLoading2 = true
      const res = await emailBindApi({
        mark: this.form.phone,
        code: this.form.code,
        newmark: this.form.email
      })
      if (res.status === 1) {
        this.timerStart2()
      } else {
        this.$q.notify(this.$i18n.locale === 'en' ? res.contenten : res.content)
      }
      this.SendCodeLoading2 = false
    },
    // 倒计时开始
    timerStart () {
      if (this.SendCodeDisabled) {
        return
      }
      this.SendCodeDisabled = true
      let timer = null
      this.time = 60
      timer = setInterval(() => {
        this.time--
        if (this.time === 0) {
          clearTimeout(timer)
          this.time = ''
          this.SendCodeDisabled = false
        }
      }, 1000)
    },
    // 倒计时开始
    timerStart2 () {
      if (this.SendCodeDisabled2) {
        return
      }
      this.SendCodeDisabled2 = true
      let timer = null
      this.time2 = 60
      timer = setInterval(() => {
        this.time2--
        if (this.time2 === 0) {
          clearTimeout(timer)
          this.time2 = ''
          this.SendCodeDisabled2 = false
        }
      }, 1000)
    }
  },
  setup () {
    return {
      pwdRule,
      emailRule,
      phoneRule
    }
  }
}
</script>

<style lang="scss" scoped>
@import './puinput.scss';
</style>
